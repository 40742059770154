import * as THREE from "three";
import "styles/index.scss";

import { getDimensions, applyDimensions } from "./dimensions";
import { create as createScene } from "./scene";
import { create as createLight } from "./lights";
import colors from "./colors";
import { createSea, createCloud, createSky, createFasinator } from "./objects";
import { twist } from "./animators";
import { handleMouseMove, handleTouchMove } from "./move";

const { scene, renderer, camera } = createScene();
const { shadowLight, hemisphereLight, ambientLight } = createLight();
scene.add(hemisphereLight);
scene.add(shadowLight);
scene.add(ambientLight);

const {
  fascinator,
  children: { outer, inner },
} = createFasinator();
fascinator.visible = false;
fascinator.position.x = 0;
fascinator.position.y = 120;
fascinator.position.z = -50;

scene.add(fascinator);

const container = document.getElementById("world");
container.appendChild(renderer.domElement);
window.addEventListener(
  "resize",
  () => applyDimensions({ renderer, camera }),
  false
);
document.addEventListener("mousemove", handleMouseMove(camera), false);
document.addEventListener("touchmove", handleTouchMove(camera), false);

const { sea, waves } = createSea();
sea.position.y = -800;
scene.add(sea);

const sky = createSky();
sky.position.y = -600;
scene.add(sky);

const loop = () => {
  sea.rotation.z += 0.002;
  twist({ mesh: sea, waves });

  sky.rotation.z += 0.0005;
  sky.children.forEach((cloud) => {
    cloud.children.forEach((cube, i) => {
      if (i % 2 === 0) {
        cube.rotation.x += 0.0025;
      } else {
        cube.rotation.x -= 0.0025;
      }
    });
  });

  outer.rotation.y += 0.0075;
  inner.rotation.y -= 0.025;

  renderer.render(scene, camera);
  requestAnimationFrame(loop);
};

loop();
