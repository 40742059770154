export const twist = ({ mesh, waves }) => {
  mesh.geometry.vertices.forEach((vertex, i) => {
    const { x, y, z, angle, amplitude, speed } = waves[i];
    vertex.x = x + Math.cos(angle) * amplitude;
    vertex.y = y + Math.sin(angle) * amplitude;
    waves[i].angle += speed;
  });

  mesh.geometry.verticesNeedUpdate = true;
};
