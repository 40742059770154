import { getDimensions } from "./dimensions";

const MAX_MOVEMENT = 15;

export const handleMouseMove = camera => {
  const {
    position: { x: initialX, y: initialY, z: initialZ }
  } = camera;

  return event => {
    const { HEIGHT, WIDTH } = getDimensions();
    const x = -1 + (event.clientX / WIDTH) * 2;
    const y = 1 - (event.clientY / HEIGHT) * 2;

    const dx = (event.clientX - WIDTH) / WIDTH;
    const dy = (event.clientY - HEIGHT) / HEIGHT;
    const distance = Math.sqrt(dx * dx + dy * dy);

    camera.position.x = initialX + MAX_MOVEMENT * x;
    camera.position.y = initialY + MAX_MOVEMENT * y;
    camera.position.z = initialZ + MAX_MOVEMENT * distance;
  };
};

export const handleTouchMove = camera => {
  const {
    position: { x: initialX, y: initialY, z: initialZ }
  } = camera;

  return event => {
    event.preventDefault();
    const { HEIGHT, WIDTH } = getDimensions();
    const x = -1 + (event.touches[0].pageX / WIDTH) * 2;
    const y = 1 - (event.touches[0].pageY / HEIGHT) * 2;

    const dx = (event.touches[0].pageX - WIDTH) / WIDTH;
    const dy = (event.touches[0].pageY - HEIGHT) / HEIGHT;
    const distance = Math.sqrt(dx * dx + dy * dy);

    camera.position.x = initialX + MAX_MOVEMENT * x;
    camera.position.y = initialY + MAX_MOVEMENT * y;
    camera.position.z = initialZ + MAX_MOVEMENT * distance;
  };
};
